function submitVip() {
	var email = $('#vip-email').val().trim();
	var birthday = $('#vip-birthday').val().replace(/[^0-9]/g, '').trim();
	if (birthday.length !== 8 && birthday.length !== 0) {
		$('.vip-alert').show();
		$('.vip-alert-message').text('Please use YYYY-MM-DD for your birthday.');
		return false;
	}
	$.ajax({
		type     : "post",
		url      : "/api/contact/vip",
		data     : { email: email, birthday: birthday, location: site },
		cache    : false,
		dataType : 'json',
		complete : function() {
			$('#vip-form').hide();
			$('#vip-thankyou').show();
		}
	});
	return false;
};
